import { render, staticRenderFns } from "./courtsPriceList.vue?vue&type=template&id=11c88e11&scoped=true&"
import script from "./courtsPriceList.vue?vue&type=script&lang=js&"
export * from "./courtsPriceList.vue?vue&type=script&lang=js&"
import style0 from "./courtsPriceList.vue?vue&type=style&index=0&id=11c88e11&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11c88e11",
  null
  
)

export default component.exports